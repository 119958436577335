@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spin-slow {
  animation: spin 2s linear infinite;
}
.Plans-dialogue{
  max-width: 1076px;
  margin: 0 auto;
}
.Plans-dialogues{
  max-width: 1092px;
  margin: 0 auto;
}
.padding-dialogue{
  padding-bottom: 150px;
}
.border-gradient{
  background-image: linear-gradient(90deg, rgba(80, 208, 73, 1.00) 0%, rgba(203, 196, 53, 1.00) 100%);
	border-radius: 40px;
  box-sizing: border-box;
	color: #fff;
	letter-spacing: 1px;
	padding: 1px;
  text-decoration: none;
/* 	text-transform: uppercase; */
	z-index: 2;
}
.border-gradient span {
	align-items: center;
	background: #181520;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background .5s ease;
	width: 100%;
  padding: 8px 15px;
  gap: 10px;
}
.signUpToday.border-gradient span{
  padding: 14px 60px;
  font-size: large;
}

.border-gradient:hover span {
	background: transparent;
}
.border-gradient:hover {
	color: #181520;
}
@layer utilities {
  .text-gradient {
    background: linear-gradient(90deg, #302D38 0%, #FFF 100%);
    -webkit-background-clip: text;  /* For Safari */
    background-clip: text;          /* For modern browsers */
    color: transparent;
  }
  .hero-text-gradient {
    background: linear-gradient(90deg, rgba(125, 204, 120, 1), rgba(211, 206, 101, 1), rgba(168, 123, 207, 1));
    -webkit-background-clip: text;  /* For Safari */
    background-clip: text;          /* For modern browsers */
    color: transparent;
  }
  .text-gradient-2 {
    background: linear-gradient(90deg, rgba(80, 208, 73, 1), rgba(203, 196, 53, 1));
    -webkit-background-clip: text;  /* For Safari */
    background-clip: text;          /* For modern browsers */
    color: transparent;
  }
  /* .border-gradient {
    position: relative;
    padding: 10px 20px; 
    border-radius: 50px; 
    overflow: hidden;
  }
  
  .border-gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px; 
    background: linear-gradient(90deg, rgba(80, 208, 73, 1), rgba(203, 196, 53, 1));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    z-index: -1;
  } */

  .border-gradient-2 {
    position: relative;
    border-radius: 50px; /* Ensures rounded shape */
    overflow: hidden;
  }
  
  .border-gradient-2::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50px;
    padding: 2px; /* Controls border thickness */
    background: linear-gradient(90deg, rgba(80, 208, 73, 1), rgba(203, 196, 53, 1));
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
    z-index: -1;
  }
  .border-gradient-generate{
    position: relative;
    padding: 15px 20px; /* Adjust for inner button padding */
    border-radius: 50px; /* Ensures rounded shape */
    overflow: hidden;
    border:1px solid #50D049

  }
  .frame-border-gradient {
    position: relative;
    padding: 16px; /* Space inside the border */
    border-radius: 16px; /* Adjust for rounded borders */
  }
  
  .frame-border-gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    padding: 7px;
    background: linear-gradient(90deg, rgba(73, 141, 208, 1), rgba(80, 208, 73, 1), rgba(203, 196, 52, 1), rgba(200, 61, 215, 1), rgba(236, 44, 76, 1));
    background-size: 200% 200%; /* Make the background larger to allow for movement */
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    animation: move-gradient 3s linear infinite; /* Add the animation */
}

/* Keyframes for moving the gradient */
@keyframes move-gradient {
    0% {
        background-position: 0% 50%; /* Start position */
    }
    100% {
        background-position: 100% 50%; /* End position (move horizontally) */
    }
}


  
  .frame-border-gradient > * {
    position: relative;
    z-index: 1;
  }
}
@media only screen and (max-width: 1072px) and (min-width: 833px){
  .card-button-container-main{
    flex-direction: column;
    gap: 15px;
  }
}
@media only screen and (max-width: 832px) and (min-width: 640px){
  .sm\:grid-cols-2 {
    grid-template-columns: auto !important;
}
.animated-text-container{
  flex-direction: column !important;
  gap: 0.5rem !important;
}
}
@media only screen and (max-width: 560px) and (min-width: 486px){
  .card-button-container-main{
    display: block;
  }
  .card-button-container{
    flex-direction: column !important;
    gap: 1rem !important;
    padding-bottom: 15px;
  }
  .card-button{
    width: 100% !important;
  }
}

@media only screen and (max-width: 420px) and (min-width: 344px){
  .animated-text{
    width: 250px;
  }
}

@media only screen and (max-width: 560px) and (min-width: 320px){
  .card-button-container{
    flex-direction: column !important;
    gap: 1rem !important;
    padding-bottom: 15px;
  }
  .card-button-container-main{
    display: block;
  }
  .card-button{
    width: 100% !important;
  }
  .card-name{
    flex-direction: column !important;
  }
  .difficulty-level{
    justify-content: space-between !important;
  }
}
@media only screen and (max-width: 767px) and (min-width: 320px){
  header{
    position: absolute;
    z-index: 999;
    top: 0px;
    right: 0;
    width: 100%;
    max-width: 100%;
  }
  .Plans-dialogue{
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .frame-border-gradient{
    top:58px
  }
  .padding-dialogue{
    padding-bottom: 50px;
  }
  .border-gradient span{
    padding: 8px 15px;
  }
}